<div *ngIf="currIndex && !isMobileView" class="top-4 left-5 absolute" [@slideInFromTopAnimation]="isAnimated(!!currIndex && !isMobileView)">
	<img loading="lazy" src="assets/images/maia_logo_transparent.png" alt="maia logo desktop view" />
</div>
<div class="sm:w-2/3 lg:w-1/2 w-full h-full sm:max-h-[90vh] sm:rounded-xl sm:bg-white" #sliderCont>
	<div class="w-full h-full flex flex-col items-center justify-evenly">
		<div class="relative w-1/2 max-w-[200px] after:content-['']
            after:block
            after:pt-[100%]
            after:w-full">
			<ng-lottie [options]="options" width="100%" height="100%" (animationCreated)="handleAnimation($event)" (configReady)="configReady($event)" class="absolute w-full h-full"></ng-lottie>
		</div>
		<div class="w-full">
			<maia-stepper (stepperStatus)="getStepperStatus($event)" (stepperAnimationControl)="getStepperAnimationControl($event)" [next]="next" [prev]="prev" [isDisabled]="false" [autoHeight]="true" [slidesPerView]="1" [spaceBetween]="0" [className]="'swiper-fade-slide'" (stepperInit)="sliderReady()" class="w-10/12 mx-auto">
				<ng-template maia-stepper-section>
					<div class="flex flex-col justify-center items-center text-center pb-5">
						<span class="text-lg mb-2" [innerHTML]="'ONBOARD.slide1.sup' | translate"></span>
						<img loading="lazy" src="assets/images/maia_logo.png" alt="maia logo" />
						<span class="text-lg mb-6 mt-2" [innerHTML]="'ONBOARD.slide1.text1' | translate"></span>
						<span class="text-lg leading-6" [innerHTML]="'ONBOARD.slide1.text2' | translate"></span>
						<span class="text-lg leading-6 mb-2" [innerHTML]="'ONBOARD.slide1.text3' | translate"></span>
					</div>
				</ng-template>
				<ng-template maia-stepper-section>
					<div class="flex flex-col justify-center items-center text-center pb-5">
						<span class="text-3xl text-green-dark-900 leading-[32px] mb-2" [innerHTML]="'ONBOARD.slide2.main' | translate"></span>
						<span class="text-lg leading-6 mb-1" [innerHTML]="'ONBOARD.slide2.text1' | translate"></span>
						<!-- <span class="text-lg leading-6" [innerHTML]="'ONBOARD.slide2.text2' | translate"></span> -->
					</div>
				</ng-template>
				<ng-template maia-stepper-section>
					<div class="flex flex-col justify-center items-center text-center pb-5">
						<span class="text-3xl text-green-dark-900 leading-[32px]" [innerHTML]="'ONBOARD.slide3.main' | translate"></span>
						<span class="text-3xl text-green-dark-900 leading-[32px] mb-4" [innerHTML]="'ONBOARD.slide3.main2' | translate"></span>
						<span class="text-lg leading-6" [innerHTML]="'ONBOARD.slide3.text1' | translate"></span>
					</div>
				</ng-template>
				<ng-template maia-stepper-section>
					<div class="flex flex-col justify-center items-center text-center pb-6">
						<span class="text-3xl text-green-dark-900 leading-[32px]" [innerHTML]="'ONBOARD.slide4.main' | translate"></span>
					</div>
				</ng-template>
			</maia-stepper>
		</div>
		<div class="py-5">
			<maia-button [type]="'button'" [customClass]="'transition-all'" [btnType]="!done ? btnType.PRIMARY_EMPTY : btnType.PRIMARY" [label]="(!done ? 'COMMON.next' : 'ONBOARD.start') | translate" (onClick)="onNext()"></maia-button>
		</div>
	</div>
</div>