import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationDataModel, NotificationService } from '@services/notification.service';

@Component({
	selector: 'maia-notification-snackbar',
	templateUrl: './notification-snackbar.component.html',
	styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: NotificationDataModel,
		private notificationService: NotificationService
	) { }


	dismissNotification() {
		this.notificationService.closeNotification();
	}
}
