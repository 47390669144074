import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { authGuard } from './guards/auth.guard';
import { boardedGuard } from './guards/boarded.guard';
import { SubDomainGuard } from './guards/subdomain.guard';
import { animationStateProperty } from '@utils/animations';
import { AppRoutes } from '@utils/routes';
import { showBackgroundStateProperty } from '@utils/genericUtils';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { uknowResolver } from './resolvers/uknow-resolver.service';
import { mobileGuard } from './guards/mobile.guard';
import { forceRefreshGuard } from './guards/force-refresh.guard';
import { UnderMaintainanceComponent } from './components/under-maintainance/under-maintainance.component';
import { ForcedUpdateComponent } from './components/forced-update/forced-update.component';

const routes: Routes = [
	{
		path: AppRoutes.ON_BOARD,
		pathMatch: 'full',
		component: OnboardComponent,
		canMatch: [SubDomainGuard],
		data: { [showBackgroundStateProperty]: true },
	},
	{
		path: AppRoutes.AUTH,
		pathMatch: 'full',
		component: AuthComponent,
		canMatch: [SubDomainGuard],
		canActivate: [boardedGuard],
		data: { [animationStateProperty]: 1, [showBackgroundStateProperty]: true },
	},
	{
		path: AppRoutes.SIGN_UP,
		pathMatch: 'full',
		component: SignUpComponent,
		canMatch: [SubDomainGuard],
		canActivate: [boardedGuard],
		data: { [animationStateProperty]: 2, [showBackgroundStateProperty]: true },
	},
	{
		path: AppRoutes.WELCOME,
		pathMatch: 'full',
		component: WelcomeComponent,
		canMatch: [SubDomainGuard],
		canActivate: [boardedGuard],
		data: { [animationStateProperty]: 3, [showBackgroundStateProperty]: true },
	},
	{
		path: AppRoutes.SHARE + '/:id',
		pathMatch: 'full',
		canMatch: [SubDomainGuard],
		loadChildren: () => import('./components/chat-share/chat-share.module').then((m) => m.ChatShareModule),
	},
	{
		path: AppRoutes.STORES,
		pathMatch: 'full',
		canMatch: [SubDomainGuard],
		loadChildren: () => import('./components/maia-stores/maia-stores.module').then((m) => m.MaiaStoresModule),
	},
	{
		path: AppRoutes.UKNOW,
		pathMatch: 'prefix',
		loadChildren: () => import('./components/uknow/uknow.module').then((m) => m.UknowModule),
		resolve: { attachments: uknowResolver }
	},
	{
		path: AppRoutes.UNDER_MAINTAINANCE,
		pathMatch: 'full',
		component: UnderMaintainanceComponent
	},
	{
		path: AppRoutes.FORCED_UPDATE,
		pathMatch: 'full',
		component: ForcedUpdateComponent,
		data: { [animationStateProperty]: 3, [showBackgroundStateProperty]: true }
	},
	{
		path: '',
		canActivate: [boardedGuard],
		canMatch: [forceRefreshGuard],
		canActivateChild: [authGuard],
		loadChildren: () => import('./components/main/main.module').then((m) => m.MainModule),
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: NoPreloading,
			useHash: false
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
