import { UdriveFileTreeModel } from "./udrive.model";

export interface MaiaConvModel {
	userId: string,
	adamConversation: ChatSidebarItemModel[],
	kBConversation: ChatSidebarItemModel[]
}


export interface ChatSidebarItemModel {
	category: string | null,
	convId: string;
	conversationType: ResponseCategoryUiCardType;
	hasAttachments: boolean,
	isPinned: boolean,
	lastMsg: string | null;
	lastMsgTime: string;
	msgType: string;
	newMessage: number;
	title: string;
	hasUKnow: boolean;
}



export interface Query {
	connectionId: string;
	conversationId: string;
	environment: string;
	history: any[];
	id_persona: number;
	msg: string;
	task: string;
	use_personality: boolean;
	use_tts: boolean;
	userId: string;
	attachments?: { id: string, type: string }[];
	searchWeb?: boolean
}

export interface AiResponseModel {
	active_conditioning: string | null;
	active_profile: any | null;
	answer: any;
	carousel: CarouselDataModel[] | null;
	category: string | null;
	conversationId?: string;
	debug: any;
	history: any[];
	intent_active_conditioning: string | null;
	intents: null;
	kobe: null;
	language: string;
	maestroai_query: any;
	responseTime: string;
	rtk_query: null;
	serp: any[];
	msgType?: string;
	time?: string;
}


export interface SendedMessageModel {
	msg: string;
	from: string;
	msgType: MessageType;
	time: string;
}


export interface DefaultAnswerModel {
	id: number;
	id_adam_conversation_history: number;
	text: string;
	tts: string | null;
	time: string;
	answer_type: ResponseCategoryUiCardType | null;
	ui_card: ResponseCategoryUiCardType | null;
	reasoning: string | null;
	entities: any;
	carousel: CarouselDataModel[] | null;
	events: null;
	geo: GeoModel | null;
	media_card: MediaCardModel[] | null;
	like: number;
	reason: null;
	debug: any;
	score: number;
	videoShares: VideoShareModel[] | null;
	language: string;
	task: 'qna' | 'image_generation' | 'summary' | 'translation' | 'paraphrase' | null;
}

export interface DialogAnswerModel extends DefaultAnswerModel {
	data: null | any[];//DialogDataModel[],
}

export interface LocalSearchAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: LocalSearchDataModel,
}

export interface YtAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: YtDataModel,
}

export interface CalendarAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: CalendarDataModel,
}

export interface WeatherAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: WeatherDataModel[],
}

export interface RtkAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: RtkDataModel,
}

export interface MailAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: MailDataModel,
}

export interface LocalSearchV2AnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: LocalSearchDataModel[],
}

export interface QuickActionAnswerModel extends Omit<DefaultAnswerModel, 'data'> {
	data: QuickActionDataModel,
}

export interface ImageAnswerModel extends DefaultAnswerModel {
	data: any[],
	//data: Array<string | { url: string; mime: string; base64: null }>
}


export interface ImageDescriptionsAnswerModel extends DefaultAnswerModel {
	data: ImageDescriptionDataModel[],
}

export interface DialogDataModel {
	title: number;
	content: string;
	document_id: number;
}


export interface MailDataModel {
	id: number;
	id_mail: string;
	id_user: string;
	id_thread: string;
	historyId: number;
	engine: string;
	read: boolean;
	sent: boolean;
	from: string;
	to: string;
	date: string;
	subject: string;
	htmlText: null;
	plainText: string;
	labels: string[];
	attachments: { id: string; filename: string; mimeType: string }[];
	isDeleted: boolean;
	answer: string;
	action: string;
}

export interface CalendarDataModel {
	id: number,
	id_user: string,
	id_event: string,
	id_calendar: any,
	engine: string,
	title: string,
	summary: string | null,
	description: string | null,
	location: string | null,
	hangoutLink: string | null,
	isDeleted: boolean,
	updated: string | null,
	start: string,
	end: string | null,
	is_allday: boolean,
	color_id: string | null,
	htmlLink: string | null,
	organizerEmail: string
	displayName: string | null,
	created: string,
	attendees: AttendeeModel[],
	attachments: any,
	raw: any,
	embedding: number[],
}

export interface AttendeeModel {
	id: any,
	ETag: any,
	self: any,
	email: string,
	comment: any,
	optional: any,
	resource: any,
	organizer: any,
	displayName: any,
	responseStatus: string,
	additionalGuests: any
}

export interface YtDataModel {
	url: string,
	title: string,
	transcription: {
		text: string,
		start: number
		duration: number
	}[],
	video_too_long: boolean
}

export interface WeatherDataModel {
	time: string,
	value: number,
	address: string,
	location: string,
	weathercode: number,
	classification: string,
	temperature_max: number,
	temperature_min: number,
	weather_description: string
}

export interface PhotoLocalDataModel {
	width: number,
	height: number,
	photo_reference: string,
	html_attributions: string[]
}

export interface OpeningHoursModel {
	open_now: boolean,
	periods: {
		close: { day: number, time: string },
		open: { day: number, time: string }
	}[]
}

export interface LocalSearchDataModel {
	address: string;
	description: string;
	description_tts: string;
	media_card: MediaCardModel[];
	name: string;
	opening_hours: OpeningHoursModel;
	phone_number: string;
	photos?: PhotoLocalDataModel[];
	position: {
		latitude: number;
		longitude: number
	}
	price_level: number;
	rating: number;
	website: string;
}

export interface RtkDataModel {
	log: [],
	related_queries: {
		web_query: string,
		subjects: string[],
		subject_info: string[],
		query_type: string,
		search_period: string,
		alternatives: string[],
		people_also_ask: string[],
		bricks: string[],
		rtk_query: string,
		conversation_context: string
	}
	rtk_knowledge: {
		url: string,
		title: string,
		content: string,
		meta_lang: string,
		source_url: string,
		type: string,
		snippet: string,
		language: string,
		thumbnailUrl: string,
		encodingFormat: null,
		num_words: number,
		reading_time: number,
		isFamilyFriendly: boolean
	}[]
}

export interface CarouselDataModel {
	id: string,
	sku: number,
	city: string,
	link: string,
	name: string,
	price: string,
	title: string,
	content: string,
	details: {
		data: string,
		iconName: string
	}[],
	summary: string,
	isContent: boolean,
	isCarousel: boolean,
	description: string,
	large_image: string,
	small_image: string,
	link_list_post_form_data: { name: string, value: string }[]
}

export interface QuickActionDataModel {
	lang: string,
	action: string,
	id_file: number,
	index_max: number,
	paragraphs: {
		index_par: number,
		paragraph: string
	}[]
}

export interface ImageDescriptionDataModel {
	src: string;
	id_file: number;
	extension: string;
	file_name: string;
	description: string;
}



export type MessageResponseType = (SendedMessageModel & DefaultAnswerModel);

export interface MessageModel {
	active_conditioning: string | null,
	active_profile: string | null;
	id_adam_conversation_history: number,
	isPinned: boolean,
	isRead: boolean,
	isUknow: boolean,
	msg: any[];
	name: string;
	task: string;
	time: string;
	title: string;
	freeTalk: boolean,
}

export interface WeatherCode {
	day: {
		description: string,
		image: string
	},
	night: {
		image: string
		description: string,
	}
}

export interface TtsSingleAudioReqModel {
	userId: string | null;
	conversationId: string;
	environment: string;
	text?: string;
	lang: string;
	speaker: string;
	stream_chunk_size: number;
	temperature: number
	overlap_wav_len: number;
	length_penalty: number;
	top_p: number;
	speed: number;
	stream: boolean;
	id_answer: number;
	id_adam_conversation_history?: number,
	answer: DefaultAnswerModel | null,
	connectionId: string
}

export interface MediaCardModel {
	title: string,
	contents: {
		text: string,
		image: string[],
		pano: string[]
	}[],
	type: string
}

export interface SharedListModel {
	id: number;
	key: string;
	user_id: string;
	conversation_id: string;
	create_date: string;
	chat: null
}

export interface WsLogModel {
	conversationId: string;
	msg: string;
	progress: number;
	tag: string;
}


export interface ConvDriveCUModel {
	id?: number,
	conversation_id: string;
	id_user: string,
	attachments: {
		id?: number,
		order: number,
		url?: string
	}[],
	conv_obj: null | string,
}

export interface WsLogModel {
	conversationId: string;
	msg: string;
	progress: number;
	tag: string;
}

export interface ConvDriveGetAttachmentsModel {
	id: number,
	attachments: AttachmentGetModel[];
	title: string | null;
	conv_obj: string | null;
}

export interface UknowAttachByHostModel extends ConvDriveGetAttachmentsModel {
	uknow_id: number;
	user_id: string;
	welcome_messages: { [key: string]: string }[]
}

export interface DetachFromChatModels {
	id: string,
	title: string,
	isUknowDel: boolean
}

export interface AttachmentGetModel {
	id: number,
	order: number,
	url: string,
	uDriveFile: UdriveFileTreeModel
}


export interface GeoModel {
	location: string,
	address: string,
	country: string,
	position: {
		latitude: number,
		longitude: number
	}
}

export enum MessageType {
	Q = 'Q',
	A = 'A',
}



export enum ResponseCategoryUiCardType {
	EMAIL = 'email',
	LOCAL_SEARCH = 'local_search',
	LOCAL_SEARCH_v2 = 'local_search_v2',
	DIALOG = 'dialog',
	SALUTATION = "salutation",
	CALENDAR = "calendar",
	KNOWLEDGE = "web_search",
	YOUTUBE = "youtube",
	RTK = "rtk",
	WEATHER = "weather",
	UKNOW = 'uknow',
	SUMMARIZE = 'qa_summarize',
	TRANSLATE = 'qa_translate',
	PARAPHRASE = 'qa_paraphrase',
	TRANSCRIBE = 'qa_transcribe',
	DESCRIBE = 'qa_describe',
	IMAGEDESCRIPTION = 'image_description',
	IMAGE = 'image',
	MSG_EMPTY = 'error_adam_answer_empty',
	VIDEO = 'video',
	NONE = ''
}

export interface SelectedChatModel {
	chatId: string;
	chatTitle: string;
	conversationType: string;
	disableWebSearch: boolean;
	hasAttachments: boolean;
	hasUKnow: boolean;
	isNew: boolean;
	shareDate: string;
}

export interface VideoShareModel {
	id: string;
	id_user: string;
	conversation_id: string;
	id_conversation_answer: number;
	title: string;
	share_key: string;
	is_local: boolean;
	data_index: number | null;
}

export interface VideoShareCreateModel {
	conversation_id: string;
	id_conversation_answer: number;
	title: string;
	is_local: boolean;
	data_index: number | null;
	url: string
	width: number;
	height: number;
	seconds: number;
}


export enum ReplaceableString {
	NO_ANSWER = 'no answer',
	WEATHER = 'weather_v2',
	LOCAL_SEARCH = 'local_search_v2',
	MSG_INTERRUPTED = 'msg_interrupted',
	MSG_EMPTY = 'error_adam_answer_empty',
	MSG_FILTER = 'error_adam_content_filter',
	MSG_LENGTH_EXCEEDED = 'error_adam_context_length_exceeded',
	MSG_ERROR_YOUTUBE_CONTENT = 'error_adam_youtube_content_error',
	MSG_MALFORMED_LANGUAGE = 'error_adam_malformed_language',
	MSG_ADAM_ERROR_IMAGE_DESC = 'adam_error_image_desc',
	MSG_ADAM_ERROR_IMAGE = 'adam_error_image',
	MSG_COIN_OVER = 'coin_over',
	EMPTY = ' '
}

export enum NumberDay {
	'COMMON.DAYS.monday',
	"COMMON.DAYS.tuesday",
	"COMMON.DAYS.wednesday",
	"COMMON.DAYS.thursday",
	"COMMON.DAYS.friday",
	"COMMON.DAYS.saturday",
	"COMMON.DAYS.sunday"
}

// export interface AnswerEntities {
// 	text: string;
// 	category: string;
// 	subCategory: string | null;
// 	confidenceScore: number;
// 	length: number;
// 	offset: number;
// }