import { Component } from '@angular/core';
import { ModalRef } from '../modal/modal-ref';

@Component({
	selector: 'maia-coin-over-error',
	templateUrl: './coin-over-error.component.html',
	styleUrls: ['./coin-over-error.component.scss']
})
export class CoinOverErrorComponent {

	constructor(
		public dialogRef: ModalRef
	) { }


	closeModal() {
		this.dialogRef.close();
	}
}
