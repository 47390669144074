import { createReducer, on, Action } from '@ngrx/store';
import { UdriveState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/udrive.action';
import { ActionType, UdriveFileTreeModel } from '@models/udrive.model';
import { PERSONAL_FOLDER_NAME } from '@constants/udrive.constant';
import { TimeUtils } from '@utils/times';
import { formatSpaceUnits } from '@utils/formatSpaceUnits';


export const reducer = createReducer(
	initialAppState.UdriveState,
	on(actions.setSidePanelOpen, (state, payload): UdriveState => {
		return {
			...state,
			sidePanelOpen: payload.value,
			currPath: '/',
			currAction: ActionType.NONE,
			currInputValue: '',
			currEditedNode: null,
			validInputValue: false,
			multiSelectionActive: false,
			multiSelectionItem: [],
			expandedFoldersList: [],
			detachNeeded: null
		}
	}),
	on(actions.loadUDrive, (state, payload): UdriveState => {
		return {
			...state,
			loadStore: payload.value
		}
	}),
	on(actions.DetachNeeded, (state): UdriveState => {
		return {
			...state,
			loadStore: false
		}
	}),
	on(actions.getUdrive, (state): UdriveState => {
		return {
			...state,
			fileStored: [],
			loadStore: true,

		}
	}),
	on(actions.setUdrive, (state, payload): UdriveState => {
		return {
			...state,
			fileStored: [...payload.udriveResponse.uDriveFiles],
			sizeStore: { ...payload.udriveResponse.uDriveSize },
			canMultiselect: payload.chatAttachLength < 11,
			loadStore: false,
			uploadCount: payload.udriveResponse.uploadCount,
			multiSelectionItem: []
		}
	}),
	on(actions.deleteUdriveItem, actions.deleteSelectedAttachments, (state): UdriveState => {
		return {
			...state,
			loadStore: true,
		}
	}),
	on(actions.selectFolder, (state, payload): UdriveState => {
		return {
			...state,
			currPath: payload.value,
			validInputValue: false,
			currEditedNode: null,
			currInputValue: '',
			multiSelectionActive: false,
			multiSelectionItem: []
		}
	}),
	on(actions.selectAll, (state): UdriveState => {
		let multiSelect: number[] = [];
		let from = state.currFiles.length > 0 ? state.currFiles : state.fileStored;
		if (state.currPath === '/') {
			from.forEach((file) => {
				if (file.path === state.currPath && file.extension !== 'folder') multiSelect.push(file.id)
			})
		} else {
			let group = from.filter(file => {
				return file.file_name === state.currPath
			})[0]
			group.subfile!.forEach((file) => {
				multiSelect.push(file.id)
			})
		}
		return {
			...state,
			multiSelectionItem: [...multiSelect],
			canMultiselect: multiSelect.length < 11,
		}
	}),
	on(actions.setCurrAction, (state, payload): UdriveState => {
		return {
			...state,
			currAction: payload.value,
			validInputValue: false,
			currEditedNode: null,
			currInputValue: ''
		}
	}),
	on(actions.setValidInput, (state, payload): UdriveState => {
		return {
			...state,
			currInputValue: payload.value
		}
	}),
	on(actions.setCurrEditedNode, (state, payload): UdriveState => {
		return {
			...state,
			currEditedNode: payload.value,
			currInputValue: payload.value.file_name.replace(payload.value.extension!, ''),
			currAction: payload.value.extension === 'folder' ? ActionType.EDITFOLDER : payload.value.extension === 'url' ? ActionType.EDITURL : ActionType.EDITFILE,
		}
	}),
	on(actions.resetAction, (state, payload): UdriveState => {
		return {
			...state,
			currAction: ActionType.NONE,
			validInputValue: false,
			currEditedNode: null,
			urlUploadError: false,
			urlValidationStatus: 'none',
			currInputValue: ''
		}
	}),
	on(actions.setMultiSelectionList, (state, payload): UdriveState => {
		return {
			...state,
			multiSelectionItem: [...payload.list],
			canMultiselect: payload.canMultiselect
		}
	}),
	on(actions.toggleMultiSelection, (state): UdriveState => {
		return {
			...state,
			multiSelectionActive: !state.multiSelectionActive,
			multiSelectionItem: []
		}
	}),
	on(actions.setExpandedFoldersList, (state, payload): UdriveState => {
		return {
			...state,
			expandedFoldersList: [...payload.value]
		}
	}),
	on(actions.setFileStatus, (state, payload): UdriveState => {
		let tmpAttachments: UdriveFileTreeModel[] = [...state.fileStored];
		tmpAttachments = tmpAttachments.map((attach: UdriveFileTreeModel) => {
			if (attach.id === payload.value) {
				return {
					...attach,
					status: 100
				}
			} else {
				return attach
			}
		});
		return {
			...state,
			fileStored: [...tmpAttachments]
		}
	}),
	on(actions.DetachNeeded, (state, payload): UdriveState => {
		return {
			...state,
			detachNeeded: payload.value
		}
	}),
	on(actions.ChatDetach, (state, payload): UdriveState => {
		return {
			...state,
			loadStore: true,
		}
	}),
	on(actions.ResetDetach, (state): UdriveState => {
		return {
			...state,
			detachNeeded: null
		}
	}),
	// on(actions.urlUploadOk, (state, payload): UdriveState => {
	// 	return {
	// 		...state,
	// 		urlUploadError: false,
	// 		loadStore: false,
	// 		currAction: ActionType.NONE,
	// 		urlValidationStatus: 'none'
	// 	}
	// }),
	on(actions.urlUploadError, (state): UdriveState => {
		return {
			...state,
			urlUploadError: true,
			loadStore: false,
			urlValidationStatus: 'none'
		}
	}),
	on(actions.addUdriveFile, (state, payload): UdriveState => {
		let newTotalFilesSize = state.sizeStore.totalSize;
		let newUdriveSize = state.sizeStore.uDriveSize;
		let files = payload.formData.getAll('file');
		files.forEach((file: any) => {
			newTotalFilesSize += (formatSpaceUnits(file.size) / (1 / 1048576));
			newUdriveSize += (formatSpaceUnits(file.size) / (1 / 1048576));
		})
		return {
			...state,
			urlValidationStatus: 'none',
			urlUploadError: false,
			loadStore: true,
			sizeStore: {
				...state.sizeStore,
				totalSize: newTotalFilesSize,
				uDriveSize: newUdriveSize
			}
		}
	}),
	on(actions.addUdriveFolder, (state): UdriveState => {
		return {
			...state,
			urlValidationStatus: 'none',
			urlUploadError: false,
			loadStore: true
		}
	}),
	on(actions.addUdriveUrl, (state): UdriveState => {
		return {
			...state,
			urlValidationStatus: 'inprogress',
			urlUploadError: false
		}
	}),
	on(actions.CalculateFileEmbeddings, (state, payload): UdriveState => {
		let currStored = [...state.fileStored];
		let selectedeFile = findFileById(currStored, payload.attachId)!;
		selectedeFile = {
			...selectedeFile,
			status: 0
		}
		if (selectedeFile!.path === '/') {
			//nella root
			currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === selectedeFile.id)] = { ...selectedeFile };
		} else {
			//nel folder
			let parentFolder = findFileParent(currStored, selectedeFile.path);
			parentFolder.subfile![parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === selectedeFile.id)] = { ...selectedeFile };
			currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
		}
		return {
			...state,
			fileStored: [...currStored]
		}
	}),
	on(actions.SetEmailAddress, (state, payload): UdriveState => {
		return {
			...state,
			emailsAddress: [...payload.emailAddress]
		}
	}),
	on(actions.updateFileNotes, (state, payload): UdriveState => {
		let currId = state.currEditedNode!.id;
		let currPath = state.currEditedNode!.path;
		let parentFile: UdriveFileTreeModel;
		let currStored = [...state.fileStored];
		if (currPath === '/') {
			parentFile = JSON.parse(JSON.stringify(currStored.find((file: UdriveFileTreeModel) => {
				return file.id === currId
			})));
			parentFile.notes = [...payload.notes];
		} else {
			parentFile = JSON.parse(JSON.stringify(currStored.find((file: UdriveFileTreeModel) => {
				return file.file_name === currPath.replace('/', '');
			})));
			parentFile?.subfile!.map((file: UdriveFileTreeModel) => {
				if (file.id === currId) {
					file.notes = [...payload.notes]
				}
			});
		}
		currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFile.id)] = { ...parentFile! };
		return {
			...state,
			currEditedNode: {
				...state.currEditedNode!,
				notes: [...payload.notes]
			},
			fileStored: [...currStored],
			loadStore: true
		}
	}),
	on(actions.addFakeFolder, (state): UdriveState => {
		let fakeFolder: UdriveFileTreeModel = {
			id: -1,
			id_user: '',
			file_name: '',
			extension: 'folder',
			notes: null,
			path: '/',
			size: 0,
			status: 0,
			upload_date: null,
			embeddings: null,
			subfile: [],
			src: null,
			emb_lang: null
		}
		return {
			...state,
			fileStored: [...state.fileStored, { ...fakeFolder }]
		}
	}),
	on(actions.cancelFakeFolder, (state): UdriveState => {
		let fileCopy = [...state.fileStored]
		fileCopy.pop();
		return {
			...state,
			fileStored: [...fileCopy],
			currAction: ActionType.NONE
		}
	}),
	on(actions.updateUdrive, (state, payload): UdriveState => {
		let currStored = [...state.fileStored];
		let newFile = payload.updatedFile;
		let oldFile = findFileById(currStored, newFile.id);
		//update object info
		if (oldFile!.path === newFile.path) {
			//update object inside root
			if (newFile.path === '/') {
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
			} else {
				//update object inside folder
				let parentFolder = findFileParent(currStored, newFile.path);
				parentFolder.subfile![parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
			}
			//move object
		} else {
			if (oldFile!.path === '/' && newFile!.path !== '/') {
				//da root a folder
				let newParentFolder = findFileParent(currStored, newFile!.path);
				if (!newParentFolder.subfile) newParentFolder.subfile = [];
				newParentFolder.subfile.push({ ...newFile });
				newParentFolder.subfile.sort((a: UdriveFileTreeModel, b: UdriveFileTreeModel) => a.id - b.id)
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
				currStored.splice(currStored.findIndex((file: UdriveFileTreeModel) => file.id === newFile!.id), 1)

			} else if (oldFile!.path !== '/' && newFile!.path === '/') {
				//da folder a root
				currStored.push({ ...newFile });
				let oldParentFolder = findFileParent(currStored, oldFile!.path);
				oldParentFolder.subfile!.splice(oldParentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				if (oldParentFolder.subfile!.length <= 0) oldParentFolder.subfile = null;
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === oldParentFolder.id)] = { ...oldParentFolder };

			} else if (oldFile!.path !== '/' && newFile!.path !== '/') {
				//da folder a folder
				let newParentFolder = findFileParent(currStored, newFile!.path);
				if (!newParentFolder.subfile) newParentFolder.subfile = [];
				newParentFolder.subfile.push({ ...newFile });
				newParentFolder.subfile.sort((a: UdriveFileTreeModel, b: UdriveFileTreeModel) => a.id - b.id)
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
				let oldParentFolder = findFileParent(currStored, oldFile!.path);
				oldParentFolder.subfile!.splice(oldParentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				if (oldParentFolder.subfile!.length <= 0) oldParentFolder.subfile = null;
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === oldParentFolder.id)] = { ...oldParentFolder };
			}
		}
		currStored = currStored.filter(file => file.id >= 0);
		currStored.sort((a, b) => a.id - b.id);
		if (state.currEditedNode && (state.currEditedNode!.id === payload.updatedFile.id)) {
			return {
				...state,
				fileStored: [...currStored],
				currEditedNode: { ...payload.updatedFile },
				loadStore: false
			}
		} else {
			return {
				...state,
				fileStored: [...currStored],
				loadStore: false
			}
		}
	}),
	on(actions.updateNewUdrive, (state, payload): UdriveState => {
		let currStored = [...state.fileStored];
		let newFile = payload.updatedFile;
		let oldFile = findFileById(currStored, newFile.id);
		if (!oldFile) {
			//file nuovo
			if (payload.updatedFile.path === '/') {
				//nella root
				currStored.push({ ...payload.updatedFile })
				currStored.sort((a, b) => a.id - b.id);
			} else {
				//nel folder
				let newParentFolder = findFileParent(currStored, newFile!.path);
				if (!newParentFolder.subfile) newParentFolder.subfile = [];
				newParentFolder.subfile.push({ ...newFile });
				newParentFolder.subfile.sort((a: UdriveFileTreeModel, b: UdriveFileTreeModel) => a.id - b.id);
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
				currStored.splice(currStored.findIndex((file: UdriveFileTreeModel) => file.id === newFile!.id), 1)
			}
		}
		currStored = currStored.filter(file => file.id >= 0);
		return {
			...state,
			fileStored: [...currStored],
			loadStore: false
		}
	}),
	on(actions.removeUdriveItem, (state, payload): UdriveState => {
		let currStored = [...state.fileStored];
		let newTotalFilesSize = state.sizeStore.totalSize;
		let newUdriveSize = state.sizeStore.uDriveSize;
		payload.removedFiles.forEach((fileId: string) => {
			let deletedfile = findFileById(currStored, +fileId)!
			if (deletedfile.path === '/') {
				currStored.splice(currStored.findIndex((file: UdriveFileTreeModel) => file.id === deletedfile.id), 1)
			} else {
				let parentFolder = findFileParent(currStored, deletedfile.path);
				parentFolder.subfile!.splice(parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === deletedfile.id), 1);
				if (parentFolder.subfile!.length <= 0) parentFolder.subfile = null;
				currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
			}
			newTotalFilesSize -= (formatSpaceUnits(deletedfile.size) / (1 / 1048576));
			newUdriveSize -= (formatSpaceUnits(deletedfile.size) / (1 / 1048576));
		})
		return {
			...state,
			fileStored: [...currStored],
			loadStore: false,
			multiSelectionItem: [],
			sizeStore: {
				...state.sizeStore,
				totalSize: newTotalFilesSize,
				uDriveSize: newUdriveSize
			}
		}
	}),
	on(actions.createNewChatAttachments, (state): UdriveState => {
		return {
			...state,
			loadStore: true
		}
	}),
	on(actions.resetUdriveState, (state): UdriveState => {
		return {
			...state,
			...initialAppState.UdriveState
		}
	}),
	on(actions.setCurrFiles, (state, payload): UdriveState => {
		return {
			...state,
			currFiles: payload.currFiles ? [...payload.currFiles] : []
		}
	}),
	on(actions.sortFileList, (state, payload): UdriveState => {
		let currFiles = [...state.currFiles]
		let key: keyof UdriveFileTreeModel = (payload.active as keyof UdriveFileTreeModel);
		if (payload.direction === 'asc') {
			currFiles.sort((a, b) => {
				if (key === 'upload_date') {
					return TimeUtils.convertToTimestamp(a[key] as string) - TimeUtils.convertToTimestamp(b[key] as string);
				} else if (key === 'file_name') {
					return (a[key] as string).localeCompare(b[key] as string);
				} else {
					return a.size - b.size;
				}

			});
		}
		if (payload.direction === 'desc') {
			currFiles.sort((a, b) => {
				if (key === 'upload_date') {
					return TimeUtils.convertToTimestamp(b[key] as string) - TimeUtils.convertToTimestamp(a[key] as string)
				} else if (key === 'file_name') {
					return (b[key] as string).localeCompare(a[key] as string);
				} else {
					return b.size - a.size;
				}
			});
		}
		if (payload.direction === '') {
			currFiles.sort((a, b) => a.id - b.id);
		}
		return {
			...state,
			currFiles: [...currFiles]
		}
	})

);


function findFileById(arr: UdriveFileTreeModel[], findId: number): UdriveFileTreeModel | null {
	for (const { subfile, id } of arr) {
		if (id === findId) {
			return arr[arr.findIndex((file: UdriveFileTreeModel) => file.id === findId)];
		}
		if (subfile && subfile.length) {
			const childId = findFileById(subfile, findId);
			if (childId !== null) {
				return subfile[subfile.findIndex((file: UdriveFileTreeModel) => file.id === findId)];
			}
		}
	}
	return null;
}


function findFileParent(storedFiles: UdriveFileTreeModel[], pathToFind: string): UdriveFileTreeModel {
	return JSON.parse(JSON.stringify(storedFiles.find((file: UdriveFileTreeModel) => {
		return file.file_name === pathToFind.replace('/', '');
	})));
}


export function UdriveReducer(state: UdriveState | undefined, action: Action): UdriveState {
	return reducer(state as UdriveState, action as Action);
}
