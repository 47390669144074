import { DecodedTokenModel } from '@models/decodedToken.model';
import { ChatSidebarItemModel as ChatSidebarItemModelChat, ConvDriveGetAttachmentsModel } from '@models/chat.model';
import { MaiaApplicationPreferences, ContactsSettings } from '@models/personalSettings.model';
import { SourceManagement, UserInformations, SettingNotification, PolicyType } from '@models/setting.model';
import { GoogleCalendarModel } from '@models/google.model';
import { Contact } from '@models/contacts.model';
import { CudNodeModel, DefaultCategoriesModel, PpgData, PpgDataNode, PpgDictionaryModel } from '@models/personalGraph.model';
import { CalendarModel, CalendarEventModel } from '@models/calendar.model';
import { ActionType, DeleteAttachErrorModel, UdriveFileTreeModel, UdriveMailModel, UdriveSizesModel } from '@models/udrive.model';
import { UknowReadModel } from '@models/uknow.model';


export interface ChatState {
	chatId: string;
	chatTitle: string;
	chatList: ChatSidebarItemModelChat[] | undefined;
	searchedChatList: ChatSidebarItemModelChat[] | undefined;
	newChatList: ChatSidebarItemModelChat[] | undefined;
	conversationType: string;
	currMessages: any[];
	incomingMsg: boolean;
	currHistory: any[];
	loadChat: boolean;
	shareDate: string;
	hasAttachments: boolean;
	attachments: ConvDriveGetAttachmentsModel | null;
	isUknowChat: boolean;
	disableWebSearch: boolean;
	hasUKnow: boolean;
	uknowWillDeleted: false | number;
	sentByUser: boolean;
	chatToLoad: boolean;
	searchChat: boolean;
	chatToDel: string[];
	isMacro: boolean;
	macroType: string;
}

export interface GoogleSettingsState {
	googleConnect: boolean;
	timeZone: {
		val: string;
		settId: number | undefined;
	};
	googleCalendar?: GoogleCalendarModel[];
}

export interface ContactsState {
	contacts: Contact[];
	allContacts: Contact[];
	selectedContact: Contact | null;
	isEditActive: boolean;
	isLoading: boolean;
}

export interface SearchState {
	searchResult: any[];
	showSearchView: boolean;
}

export interface PersonalGraphState {
	showPersonalData: boolean;
	showNav: boolean;
	talkAbout: string;
	lastUpdate: string;
	ppgData: PpgData;
	selectedNode: string;
	personalName: string;
	showNodeSide: boolean;
	showDataSide: boolean;
	selectedSubNode: number;
	graphPath: string[];
	currPersonalData: PpgDataNode[];
	ppgDefaultCat: DefaultCategoriesModel;
	ppgBannedCat: string[];
	ppgDictionary: PpgDictionaryModel[];
	ppgMultipleUpdate: CudNodeModel;
	ppgSavingNode: boolean;
}

export interface PersonalSettingsState {
	maiaApplication: MaiaApplicationPreferences;
	informations: UserInformations;
	googleConnection: GoogleSettingsState;
	sourceManagement: SourceManagement;
	notification: SettingNotification | null;
	contactSettings: ContactsSettings
}

export interface TextToSpeechState {
	ttsEnabled: boolean,
	ttsCurrAnswer: number,
	ttsIgnoreAnswer: number[],
	ttsIsPlaying: boolean,
	ttsSample: string[],
	wsConnectionID: string,
	endedTts: number[]
}


export interface CalendarState {
	currentCalendar: CalendarModel | null,
	availCalendars: CalendarModel[],
	currEventSettings: CalendarEventModel[],
	//gettingEvents: boolean,
	currentEvent: CalendarEventModel | null,
	calendarWaiting: boolean | null
}

export interface UdriveState {
	sidePanelOpen: boolean;
	fileStored: UdriveFileTreeModel[];
	loadStore: boolean;
	sizeStore: UdriveSizesModel;
	currPath: string;
	currAction: ActionType;
	currEditedNode: UdriveFileTreeModel | null;
	currInputValue: string;
	currFiles: UdriveFileTreeModel[];
	validInputValue: boolean;
	multiSelectionActive: boolean;
	multiSelectionItem: number[];
	canMultiselect: boolean;
	expandedFoldersList: string[];
	detachNeeded: DeleteAttachErrorModel[] | null;
	urlUploadError: boolean;
	urlValidationStatus: 'none' | 'inprogress';
	uploadCount: number;
	emailsAddress: UdriveMailModel[] | null;
}

export interface UknowState {
	conversationList: UknowReadModel[] | null;
	selectedConversation: string;
	currMessages: any[];
	loadChat: boolean;
}

export interface IMaiaState {
	UserState: DecodedTokenModel;
	ChatState: ChatState;
	PersonalSettingsState: PersonalSettingsState;
	ContactsState: ContactsState;
	SearchState: SearchState;
	PersonalGraphState: PersonalGraphState;
	TextToSpeechState: TextToSpeechState;
	CalendarState: CalendarState;
	UdriveState: UdriveState;
	UknowState: UknowState;
}

export const initialAppState: IMaiaState = {
	UserState: {
		oid: '',
		role: '',
		subscriptionKey: '',
		name: '',
		surname: '',
		displayName: '',
		sex: '',
		emails: '',
		profilePhoto: '',
		isBase: true,
		isPro: false,
		isPlus: false,
		uDriveSize: '',
		exp: -1,
		iss: '',
		aud: ''
	},
	ChatState: {
		chatId: '',
		chatTitle: '',
		chatList: undefined,
		searchedChatList: undefined,
		newChatList: undefined,
		conversationType: '',
		currMessages: [],
		currHistory: [],
		incomingMsg: false,
		loadChat: false,
		shareDate: '',
		hasAttachments: false,
		attachments: null,
		isUknowChat: false,
		disableWebSearch: true,
		hasUKnow: false,
		uknowWillDeleted: false,
		sentByUser: false,
		chatToLoad: true,
		searchChat: false,
		chatToDel: [],
		isMacro: false,
		macroType: ''
	},
	PersonalSettingsState: {
		maiaApplication: {
			background: '',
			animation: [],
			appearance: [],
			settId: undefined,
			mobileMic: false,
			muteMaia: false,
			currPath: ''
		},
		informations: {
			address: '',
			banned: false,
			bio: null,
			birthDate: null,
			coinsSpent: 0,
			coinsTotal: 0,
			country: '',
			[PolicyType.custMarkPolicy]: false,
			[PolicyType.dataThirdPartPolicy]: false,
			disabled: false,
			displayName: '',
			email: '',
			generationLevel: '',
			id: '',
			idRole: 0,
			id_email: '',
			id_uup: null,
			imageUploadCount: 0,
			interests: null,
			isPlus: false,
			isPro: false,
			language: '',
			[PolicyType.localizationPolicy]: false,
			[PolicyType.mailCalPolicy]: false,
			maxImageUploadCount: 0,
			[PolicyType.microphonePolicy]: false,
			name: '',
			ocpApimSubscriptionKey: null,
			profilePhoto: null,
			sex: '',
			surname: '',
			uDriveSize: 0
		},
		googleConnection: {
			googleConnect: false,
			timeZone: {
				val: '',
				settId: undefined,
			},
			googleCalendar: [],
		},
		sourceManagement: {
			blackList: [],
			whiteList: [],
		},
		notification: null,
		contactSettings: { sortBy: '' }
	},
	ContactsState: {
		contacts: [],
		allContacts: [],
		selectedContact: null,
		isEditActive: false,
		isLoading: false,
	},
	SearchState: {
		searchResult: [],
		showSearchView: false,
	},
	PersonalGraphState: {
		showPersonalData: false,
		showNav: false,
		talkAbout: '',
		lastUpdate: '',
		ppgData: {},
		selectedNode: 'You',
		personalName: 'You',
		showNodeSide: false,
		showDataSide: true,
		selectedSubNode: 0,
		graphPath: ['You'],
		currPersonalData: [],
		ppgDefaultCat: {},
		ppgBannedCat: [],
		ppgDictionary: [],
		ppgMultipleUpdate: {
			createList: [],
			deleteList: [],
			updateList: [],
			id_user: ''
		},
		ppgSavingNode: false
	},
	TextToSpeechState: {
		ttsEnabled: false,
		ttsCurrAnswer: 0,
		ttsIgnoreAnswer: [],
		ttsIsPlaying: false,
		ttsSample: [],
		wsConnectionID: '',
		endedTts: []
	},
	CalendarState: {
		currentCalendar: null,
		availCalendars: [],
		currEventSettings: [],
		//gettingEvents: false,
		currentEvent: null,
		calendarWaiting: true
	},
	UdriveState: {
		sidePanelOpen: false,
		fileStored: [],
		loadStore: false,
		sizeStore: {
			uDriveSize: 0,
			emailSize: 0,
			calendarSize: 0,
			totalSize: 0,
			maxSize: 0
		},
		currPath: '/',
		currAction: ActionType.NONE,
		currInputValue: '',
		currFiles: [],
		currEditedNode: null,
		validInputValue: false,
		multiSelectionActive: false,
		multiSelectionItem: [],
		canMultiselect: true,
		expandedFoldersList: [],
		detachNeeded: null,
		urlUploadError: false,
		urlValidationStatus: 'none',
		uploadCount: 0,
		emailsAddress: null
	},
	UknowState: {
		conversationList: null,
		selectedConversation: '',
		currMessages: [],
		loadChat: false,
	}
};
